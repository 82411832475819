@import 'src/assets/styles/common/settings';

$nbColumns: 5;

.column {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-left: 0;
}

.container {
  background-color: $pure-white;
  box-sizing: border-box;
  display: none;
  flex-direction: column;
  left: 0;
  padding: 32px 20px 0 20px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 999;
  max-height: calc(var(--maxHeightToBottom, 50vh) - calc(100vh / 8));

  &.open {
    display: flex;
  }
}

.content {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat($nbColumns, minmax(0, 1fr));
  grid-auto-flow: column;
  margin: 0 auto;
  max-width: $page-max-width;
  overflow-y: auto;
  padding-left: 10px;
  width: 100%;
  padding-bottom: 20px;
}

.serviceBanner {
  margin: auto -20px 0 -20px;
}

@for $i from 1 through $nbColumns {
  .column-#{$i} {
    grid-column: $i;
  }
}
