@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

body.drawerOpen {
  overflow: hidden;
}

.mobileNavigationDrawer {
  z-index: 1000;
  position: fixed;
  overflow: hidden;
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: $pure-white;
  box-sizing: border-box;
  flex-direction: column;
  padding-top: 10px;

  &.open {
    display: flex;
  }

  .navigation {
    overflow: auto;
    padding-bottom: 30px;
  }

  .toolbar {
    margin-top: auto;
  }
}

@include minTablet() {
  .mobileNavigationDrawer .navigation {
    padding: 0 54px 30px;
  }
}
