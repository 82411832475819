@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

body {
  .customer_menu_mobile {
    .title {
      background-color: $pure-white;
      line-height: 80px;
      left: 0;
      padding: 0 15px;
      position: fixed;
      right: 0;
      top: 0;

      label {
        color: $pink-title;
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
      }

      img {
        background-color: #00000098;
        background-size: cover;
        border-radius: 100%;
        cursor: pointer;
        opacity: 1;
        width: 50px;
        height: 50px;
        position: absolute;
        right: 10px;
        top: 14px;
      }
    }

    .list {
      background: $pure-white;
      bottom: 0;
      border-top: 1px solid #ece3e4;
      left: 0;
      right: 0;
      top: 80px;
      z-index: 10;
      position: fixed;
      box-shadow: 0px -4px 3px rgba(70, 69, 69, 0.31);

      ul {
        display: flex;
        flex-direction: column;
        padding: 0 5% 0 5%;
        margin: 0;
        list-style: none;

        li {
          border-bottom: 2px dashed $pink-title;
          font-size: 18px;
          height: 65px;

          &:last-child {
            border-bottom: none;
            align-self: center;
            margin-top: 15px;

            a {
              width: 100%;
            }

            label {
              text-align: left;
              width: 100%;
            }
          }

          .logout {
            font-weight: bold;
            svg {
              margin-left: 13px;
            }

            .rightArrow {
              margin-top: 10px;
            }

            .twicImageCross {
              width: 17px;
              height: auto;
              margin-right: 10px;
              margin-top: 2px;
              opacity: 0.8;

              img {
                margin: 0;
              }
            }
          }

          a {
            display: inline-table;
            justify-content: space-between;
            color: $dark-grey;
            text-decoration: none;
            width: 87%;
            margin-top: 5px;

            &:hover {
              color: $pink-title;
            }

            &.logout:hover {
              color: $pure-black;
              font-weight: bold;
            }

            &.isActive {
              color: $pink-title;
              font-weight: bold;
              margin-top: 5px;

              label {
                cursor: default;
              }
            }

            .rightArrow {
              right: 40px;
              height: 1.5em;
              margin-bottom: 10px;
            }

            .close {
              margin-right: 10px;
            }

            img {
              margin: 5px 10px 0 0;
            }

            label {
              cursor: pointer;
              display: table-cell;
              vertical-align: middle;
              width: 80%;
            }

            .close {
              margin-right: 10px;
            }

            img {
              margin: 5px 10px 0 0;
            }

            label {
              cursor: pointer;
              display: table-cell;
              vertical-align: middle;
            }
          }
        }
      }
    }
    .redirectionHome {
      position: fixed;
      z-index: 10;
      left: 0;
      bottom: 0;
      width: 100%;
      background: #f6f0f0;
      border-top: 4px solid #ebe4e4;
      font-size: 24px;

      p {
        color: $pure-black;
        cursor: pointer;
        font-size: 24px;
        font-weight: 700;
        line-height: 80px;
        text-transform: uppercase;
        width: 100%;
        display: inline-block;
        margin: 0;

        svg {
          margin: 0 1.5% 0 1%;
        }
      }
    }
  }
}
