@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.merchCarousel {
  padding: 15px 10px 17px 10px;

  .carouselItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
      width: 100%;
    }
  }

  .carouselPaginationDots {
    bottom: -14px;

    button {
      background: #707070;
      transform: none;
      width: 6px;
      height: 6px;
      margin: 2px;

      &[aria-selected] {
        background: #E5007E;
      }
    }
  }
}

@include tablet {
  .merchCarousel {
    padding: 25px 0 45px 0;

    .carouselPaginationDots {
      bottom: -28px;

      button {
        width: 11px;
        height: 11px;
      }
    }
  }
}
