@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

body.customerNavigationOpen {
  overflow: hidden;
}

@include mobileTabletPortrait {
  .redirectionMenu {
    margin-top: 30px;
    a {
      cursor: pointer;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 700;

      svg {
        margin: 0 1.5% 0 1%;
        vertical-align: middle;
      }

      label {
        cursor: pointer;
        font-size: 15px;
        vertical-align: middle;
      }
    }
  }
}
