@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.listing {
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    border-bottom: 1px solid #ebe4e4;
    &:first-child {
      border-top: 1px solid #ebe4e4;
    }
  }
}

.button {
  box-sizing: border-box;
  background-color: $pure-white;
  border: none;
  color: #7A7A7A;
  display: flex;
  align-items: center;
  font: normal normal normal 19px/24px Lato;
  justify-content: space-between;
  padding: 15px 40px;
  text-decoration: none;
  width: 100%;

  .arrow {
    font-size: 16px;
    fill: #BFBFBF;
  }
}

.backButton {
  border: none;
  display: flex;
  align-items: center;
  color: #7A7A7A;
  background: $pure-white;
  font: normal normal bold 19px/24px $font-lato;
  padding: 15px 40px 15px 22px;
  width: 100%;

  .backArrow {
    font-size: 18px;
  }
}

.subItem {
  font: normal normal normal 14px/17px $font-lato;
  padding-bottom: 6px;
}
