@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

@include desktop {
  .header {
    min-height: 219px;
    .headerContainer {
      padding-bottom: 15px;
      &.sticky {
        padding-bottom: 0;
      }
    }
  }
}

@include minTablet {
  .header {
    min-height: 132px;
    .headerContainer {
      background: #ffffff;
    }
  }
}

.header {
  min-height: 68px;
  .headerContainer {
    position: relative;
    transition: all .30s ease;
  }
}

.headerContainer.sticky {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 20;
  box-shadow: 0 3px 6px #0000004D;
}
