@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

div#colissimo-container {
  div.colissimo_widget_widget {
    div#colissimo_widget_listePoints-container {
      a:focus,
      a:active {
        color: $green;
      }
    }
  }
}
