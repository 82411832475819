@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.root {
  align-items: center;
  background-color: $pure-black;
  bottom: 35px;
  border: none;
  border-radius: 100px;
  color: $pure-white;
  height: 48px;
  display: flex;
  justify-content: center;
  opacity: 0;
  position: fixed;
  right: 53px;
  transform: translateY(100px);
  transition: all .10s ease;
  width: 48px;
  z-index: 5;

  @include mobile {
    right: 17px;
  }

  .icon {
    width: 24px;
    height: 24px;
    transform: rotate(180deg);
  }
}

.displayButton {
  opacity: 1;
  transform: translateY(0);
}

@include mobileTabletPortrait() {
  .productPage {
    bottom: 110px;
  }

  .cartPage {
    bottom: 75px;
  }
}

@media screen and (max-width: $screen-md-max ){
  .checkoutSignUpPage {
    bottom: 75px;
  }
}

@media screen and (max-width: $screen-lg-max) {
  .checkoutShippingPage, .checkoutPaymentPage {
    bottom: 75px;
  }
}
