@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.container {
  min-width: 200px;
  padding: 10px 15px;
  text-align: center;
  box-sizing: border-box;

  .title {
    background-color: transparent;
    border-bottom: 3px solid $pink-title;
    padding-bottom: 10px;
    text-transform: uppercase;

    strong {
      font-size: 24px;
      font-weight: 500;
    }
  }

  div:nth-child(2) {
    background: $pure-white;

    ul {
      margin: 0;
      padding: 0;

      li {
        border-bottom: 2px dashed $pink-title;
        font-size: 18px;
        list-style: none none;
        padding: 10px 0 10px 0;
        text-align: left;

        &:last-child {
          border-bottom: 0;
          text-align: center;
        }

        a {
          color: $dark-grey;
          text-decoration: none;
          display: inline-table;
          margin-top: 5px;

          &:hover {
            color: $pink-title;
          }

          &.logout {
            font-weight: bold;
            &:hover {
              color: $pure-black;
              font-weight: bold;

              .twicImageCross {
                opacity: 1.5;
              }
            }
            .twicImageCross {
              width: 17px;
              height: auto;
              margin-right: 10px;
              margin-top: 2px;
              opacity: 0.8;
            }
          }

          &.isActive {
            color: $pink-title;
            font-weight: bold;
            margin-top: 5px;

            label {
              cursor: default;
            }
          }

          label {
            cursor: pointer;
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
