$afibelFontName: 'Montserrat';
$afibelFont: $afibelFontName, 'Roboto', 'Open Sans';

// Font

$font-lato: Lato;

// Colors

$pure-white: #ffffff;
$pure-black: #000000;
$dark: #333;
$grey: #a9a0a0;
$grey-2: #707070;
$grey-3: #7A7A7A;
$grey-4: #BFBFBF;
$grey-5: #F5F5F5;
$grey-6: #EFEFEF;
$grey-dark: #777777;
$grey-light: #f6f0f0;
$grey-light-2: #6B6B6B;
$grey-light-3: #EFEFEF;
$grey-light-4: #D1D1D1;
$green: #3aa104;
$lightgrey: #ccc;
$lightgreen: #76CB89;
$primary-white: #f6f0f0;
$primary-dark: #2f2727;
$primary-grey: #b3b3b3;
$secondary-grey: #ccc;
$secondary-white: #ece3e4;
$blue: #006bb4;
$secondary-blue: #00699d;
$light-red: #f86263;
$red: #e50003;
$disabled-grey: #707070;
$logo-color: #d41118;
$orange: #ff8900;
$dark-orange: #f60;
$yellow: #ffff00;
$orange-uk: #f57423;
$white-opacity: rgba(255, 255, 255, 0.8);
$black-opacity: rgba(0, 0, 0, 0.15);
$buttonChecked-color: $pure-black;
$background-grey: #ece3e4;
$brown: #2f2627;
$pink-dark: #E6007E;
$pink: #E5007F;
$pink-light: #fee4f1;
$raspberry: #D8006B;

// Colors V2
$grey-v2: #f8f8f8;
$grey-light-v2: #e3e3e3;
$green-light: #7adb87;
$primary-dark-v2: #3b3535;
$red-v2: #f16060;

// Button
$button-background-green: #3aa104;
$button-background-grey: #eee;
$button-background-red: #f86264;

// migration account v2
$pink-title: #e6007e;
$grey-default: #625f60;
$enabled-button: #64b546;
$help-bubble: #625f60b3;
$text-info: #050505cc;
$text-default: #020000;
$text-error-red: #fc0202;
$text-correct-green: #04a504;
$dark-grey: #625f60;
$background-newsletter: #ffdbf3;
$star-required-field: #fa6d6d;
$disabled-input: #625f601a;
$white-grey-background: #efefef;
$border-radius: 4px;
$formFontWeightLabel: 500;

$accountTitleSize: 20px;
$accountBorder: 1px solid $pink-title;
$accountBorderRadius: 5px;
$accountTitleMobilePadding: 0 0 10px 0;

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1400px;
$screen-xxxl-min: 1600px;
$screen-xxxxl-min: 1800px;

$screen-xsm-max: 359px;
$screen-sm-max: $screen-md-min - 1;
$screen-md-max: $screen-lg-min - 1;
$screen-lg-max: $screen-xl-min - 1;
$screen-xl-max: $screen-xxl-min - 1;

$height-header-mobile: 119px;

// Pages
$page-max-width: 1800px;
$page-container-max-width: 1240px;
$create-account-page-container-max-width: 435px;

// Icomoon
$icomoon: (
  well-being: '\e900',
  men: '\e901',
  lingerie: '\e902',
  home: '\e903',
  shoes: '\e904',
  ready-made-clothes: '\e905',
  homewear: '\e906',
);
