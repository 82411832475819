@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

div.shoppingIdea, div.alsoDiscover {
  margin-bottom: 25px;
  @include tablet {
    margin-bottom: 50px;
  }

  @include desktop {
    margin-bottom: 50px;
  }

  & > h2 {
    text-align: center;
    text-transform: uppercase;
  }
}
