@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.headerContainer {
  display: flex;
  flex-direction: column;

  &.sticky {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 999;

    .header {
      display: none;
    }
  }

  button {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;

    img {
      height: 22px;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: 100px;
    margin: 0 auto;
    max-width: $page-max-width;
    width: 100%;
    background-color: $pure-white;
    padding: 12px 20px;

    &.dnone {
      display: none;
    }

    .leftContainer, .rightContainer {
      flex-basis: auto;
      width: 100%;
    }

    .leftContainer {
      display: flex;
      align-items: center;

      .leftActionBtns {
        display: none;
        align-items: center;

        .openMenuBtn {
          display: flex;
          flex-direction: column;
          background-color: unset;
          border: none;
          padding: 0;
          margin-right: 15px;
        }
      }
    }

    .rightContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 15px;

      .closeMenuBtn {
        display: none;
      }

      .rightNavigationContainer {
        display: flex;
      }
    }
  }

  .searchBarContainer {
    display: none;
    padding: 0 16px;
    position: relative;
    background: #ffffff;

    form {
      flex: 1;
    }
  }

  .closeMenuBtn {
    display: none;
  }
}

@include tablet {
  .headerContainer {
    .header {
      height: 90px;
      padding: 10px 20px;

      &.dnone {
        display: inherit;
      }

      .leftContainer {
        width: auto;

        .leftActionBtns {
          display: flex;
        }
      }

      .rightContainer {
        width: auto;

        .closeMenuBtn {
          display: flex;
        }
      }
    }

    .closeMenuBtn {
      display: flex;
    }

    .searchBarContainer {
      display: block;
      padding: 0 20px 15px;
    }
  }
}

@include mobile {
  .headerContainer {
    .header {
      height: 68px;
      padding: 10px;

      &.dnone {
        display: inherit;
      }

      &.fullCart {
        padding-right: 20px;
      }

      .leftContainer {
        .leftActionBtns {
          display: flex;
        }
      }

      .rightContainer {
        .closeMenuBtn {
          display: flex;
        }
      }
    }

    .closeMenuBtn {
      display: flex;

      img {
        width: 18px;
      }
    }

    .searchBarContainer {
      display: block;
      padding: 0 10px 15px;
    }
  }
}

@include smallMobile {
  .headerContainer .header .leftContainer .leftActionBtns .openMenuBtn {
    margin-right: 0;
  }
}
