@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.priceContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 4px 0;
  @include mobileTabletPortrait {
    padding: 2px 0 16px 0;
  }
}

.normalPrice {
  display: flex;
  flex-direction: column;
}

.from {
  color: $primary-dark;
  font: normal normal 400 12px/14px $afibelFont;
}

.productPrice {
  font: normal normal 600 20px/28px $afibelFont;
}

.priceIfDiscount {
  color: $primary-dark;
  font: normal normal 400 13px/16px $afibelFont;
  margin-left: 5px;
  text-decoration: line-through;

  .tail {
    font: normal normal 400 11px/13px $afibelFont;
  }
}

.newPrice {
  color: $red;
  font: normal normal 600 20px/28px Montserrat;
  margin-left: 5px;
  white-space: nowrap;
}

.discountPercentage {
  color: $pure-white;
  background-color: $red;
  font: normal normal 450 10px/12px $afibelFont;
  margin: auto;
  padding: 5px;
  text-align: center;

  span {
    font: normal normal 500 13px/16px $afibelFont;
  }
}

.tail {
  font: normal normal 600 15px/28px $afibelFont;
}

.newPriceContainer {
  display: flex;
  align-items: center;
}

.lot {
  font: normal normal 15px/18px "Montserrat", "Roboto", "Open Sans"
}