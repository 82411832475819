@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.breadcrumb {
  width: calc(64% + 100px);
  box-sizing: border-box;
  margin: 30px auto;
}

.breadcrumb-items {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.breadcrumb-item {
  font-size: 13px;

  &.separator {
    color: $pink-title;
    font-size: 12px;
    font-weight: bold;
    padding: 0 5px;
    cursor: default;
    align-self: flex-start;
  }
}

.link {
  color: $pure-black;
  text-decoration: none;

  &.current {
    cursor: default;
    font-weight: 500;
    color: $dark;
  }
}

.public .breadcrumb-item {
  font-size: 12px;

  &.separator {
    color: $pure-black;
    font-weight: normal;
    padding: 0 15px;
  }
}

@include mobileTabletPortrait() {
  .breadcrumb {
    display: none;
  }
}
