@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.bodyMerchOpen {
  overflow: hidden;
}

.merchBanners {
  z-index: 1000;
  background-color: $pure-white;
  position: absolute;
  overflow: auto;
  top: calc(100% - 1px);
  height: calc(100vh - 100% + 1px);
  width: 100%;
  padding: 15px 10px;
  box-sizing: border-box;

  .bannerItem {
    margin-bottom: 22px
  }
}

@include desktop {
  .merchBanners {
    display: none;
  }
}
