@import 'src/assets/styles/common/settings';

.directOrderLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  padding: 15px 40px;
  border-top: 1px solid #ebe4e4;
  border-bottom: 1px solid #ebe4e4;
  position: relative;

  img {
    order: 2;
    height: auto;
    width: auto;
  }

  span {
    width: 124px;
    font: normal normal 500 14px/17px $font-lato;
    color: #7A7A7A;
  }

  &::after {
    display: flex;
    align-items: center;
    justify-content: center;
    content: "+";
    color: white;
    font-weight: 500;
    position: absolute;
    text-align: center;
    top: 25px;
    right: 32px;
    width: 18px;
    height: 18px;
    background: #e5007e;
    border-radius: 100%;
    font-size: 18px;
  }
}
