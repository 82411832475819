.container {
  position: relative;

  &.isLogged:hover {
    .navLink span {
      display: none;
    }

    .dropdown {
      display: flex;
    }
  }
}

@media (hover: none) {
  .container.isLogged:hover .dropdown {
    display: none;
  }
}
