@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.alert {
  display: flex;
  align-items: right;
  margin-top: 10px;
}

.icon {
  margin-right: 10px;
}

.label {
  font-weight: bold;
}

.alertSuccess {
  color: $enabled-button;
}

.alertError {
  color: red;
}

.alertWarning {
  color: $orange-uk;
}
