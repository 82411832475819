@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;

  &:hover > a > span::after,
  &.open > a > span::after {
    border-bottom: 2px solid $pink;
    top: 100%;
    bottom: -4px;
    content: '';
    display: block;
    position: absolute;
    width: calc(100% - 20px);
    z-index: 1000;
  }

  > a {
    flex: 1;
    color: $grey-3;
    cursor: pointer;
    font: normal normal normal 1rem/1.063rem $font-lato;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;

    &.disabled {
      pointer-events: none;
      cursor: default;
    }

    > span {
      position: relative;
      display: inline-block;
      padding: 10px;
    }
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    height: 100vh;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 72px;
    width: 100%;
    z-index: 998;

    &.show {
      display: block;
    }
  }
}

@include smallDesktop {
  .container > a {
    white-space: initial;
  }
}
