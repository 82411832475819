@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
}

@include desktop {
  .container {
    max-width: $page-container-max-width;
    margin: 0 auto;
    &.container-cms-page {
      max-width: 1920px;
    }
  }
}