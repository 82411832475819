@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.logo {
  flex-shrink: 0;

  h1 {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  img {
    max-width: 100%;
  }
}

@include tablet() {
  .logo img {
    height: 70px;
  }
}

@include mobile() {
  .logo img {
    height: 48px;
    width: auto;
  }
}