@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.container {
  .title {
    display: none;
  }

  .alert {
    display: none;
  }

  .children {
    padding: 30px 10px 50px;
  }
}

@include desktopTablet {
  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: calc(80% + 100px);
    box-sizing: border-box;
    margin: 0 auto 50px;

    .children {
      padding: 0;
    }

    .title {
      display: block;
      border-bottom: $accountBorder;
      margin-top: 20px;
      text-align: center;
      padding-bottom: 15px;

      label {
        color: $pink-title;
        font-size: 24px;
        font-weight: 600;
      }
    }

    .alert {
      display: block;
      margin-top: 10px;
    }

    .containerContent {
      display: flex;
      flex-direction: row;
      margin-top: 40px;

      .menu {
        margin-right: 16px;
        width: 264px;
      }

      .children {
        flex: 1;
      }
    }
  }
}

@include desktop {
  .container {
    width: calc(64% + 100px);
  }
}
