@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.container {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: -35px;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 5px;
  background: #fff;
  padding: 20px 14px;
  color: #7A7A7A;
  width: 210px;
  box-sizing: border-box;
  z-index: 100;

  .label {
    align-self: center;
    text-transform: uppercase;
    font: normal normal 500 13px/16px $font-lato;
    margin-bottom: 18px;
    padding-bottom: 10px;
    border-bottom: 2px solid #e5007f;
  }

  > ul {
    margin: 0;
    padding: 0;
    font: normal normal 500 12px/15px $font-lato;
    list-style-type: none;

    > li {
      padding: 0;
      white-space: nowrap;
      margin-bottom: 14px;

      > a {
        text-decoration: none;
        color: #7A7A7A;
        position: relative;
        display: inline-block;
        width: 100%;

        &::before {
          position: absolute;
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background-color: #e5007f;
          left: 0;
          top: 5px;
          display: none;
        }

        &:hover {
          font-weight: bold;
          padding-left: 10px;

          &::before {
              display: block;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include minTablet {
  .container {
    right: -56px;
  }
}

@include desktopLarge {
  .container {
    right: -82px;
  }
}
