@import "_tools";

@include font-face($afibelFontName, 'Montserrat', 'Regular', 300, normal);
@include font-face($afibelFontName, 'Montserrat', 'Regular', 400, normal);
@include font-face($afibelFontName, 'Montserrat', 'Medium', 450, normal);
@include font-face($afibelFontName, 'Montserrat', 'SemiBold', 500, normal);
@include font-face($afibelFontName, 'Montserrat', 'Bold', 600, normal);

@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icomoon/icomoon.ttf") format("truetype"),
    url("../../fonts/icomoon/icomoon.woff") format("woff"),
    url("../../fonts/icomoon/icomoon.svg#icomoon") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


html {
  font-size: 16px;
  scroll-behavior: smooth;
  width: 100%;
  // height: 100%;
}

body {
  color: #333;
  // font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-family: 'Montserrat', 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: normal;
  margin: 0;
  padding: 0;
}

button {
  font-family: $afibelFont;
  cursor: pointer;
  &.clickEffect:active {
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    }
}

#root {
  display: flex;
  flex-direction: column;
}

.disabled-link {
  pointer-events: none;
}

.noselect {
  -webkit-user-select: none; /* Safari */
  -webkit-touch-callout: none; /* iOS Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -ms-user-select: none; /* Internet Explorer/Edge */
  -moz-user-select: none; /* Old versions of Firefox */
  user-select: none; /* Non-prefixed version*/
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
}

// Icomoon
.fontIcon {
  @extend %icomoon;

  @each $name, $glyph in $icomoon {
    &--#{$name}::before {
      content: $glyph;
    }
  }
}

.hidden {
  display: none !important;
}

.oct_fullhp {
  margin-top: 0 !important; // Ask to Afibel to remove their css in OC
}

.oct_headerSite {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 24px;
  box-sizing: border-box;

  .oct_contentBannPromo {
    width: 100%;
  }
}

@include desktop {
  .oct_headerSite {
    height: 30px;
  }
}

@include smallDesktop {
  html {
    font-size: 14px;
  }
}
