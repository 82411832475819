@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.arrow {
  background: $pure-white;
  height: 50px;
  opacity: 0.8;
  top: calc(50% - 25px);
  width: 25px;

  &::before {
    border-left: 2px solid #464646;
    border-top: 2px solid #464646;
    content: '';
    display: block;
    height: 14px;
    margin-left: -5px;
    width: 14px;

    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  &:disabled {
    opacity: 0.33;
  }

  &.hidden {
    display: none;
  }

  svg {
    display: none !important;
  }
}

.nextArrow {
  border-radius: 100px 0 0 100px;
  right: 0;
}

.prevArrow {
  border-radius: 0 100px 100px 0;
  left: 0;

  &::before {
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin-left: 4px;
  }
}

.title {
  font: normal normal 600 24px/18px $afibelFont;
  margin: 0 auto 26px;
  max-width: calc(1180px);
  min-width: inherit;
  text-transform: uppercase;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}

@include mobileTabletPortrait {
  .title {
    font: normal normal 600 16px/18px $afibelFont;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 1240px) {
  .carouselProductsContainer {
    margin: 0;

    &.showLabelsOnMobile {
      .productLink {
        .shortLabel {
          display: block;
        }
      }
    }
  }

  .productLink {
    .shortLabel {
      display: none;
    }
  }
}