@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.productContainer {
  color: $pure-black;
  display: block;
  padding: 0;
  position: relative;
  text-align: center;
  text-decoration: none;

  span em {
    font-weight: bold;
  }

  img {
    display: inline-block;
  }
}

.productThumbnail {
  width: auto;
  position: relative;
  @include tabletPortrait {
    height: auto;
  }
  @include tabletLandscape {
    height: auto;
  }

  @include mobile {
    height: auto;
  }
}

.shortLabel {
  display: block;
  font: normal normal normal 14px/16px $afibelFont;
  overflow: hidden;
  padding-top: 6px;
  text-align: left;
}

.sticker {
  background: rgb(218, 0, 85);
  color: $pure-white;
  cursor: pointer;
  font: normal normal 500 15px $afibelFont;

  text-align: center;
  text-transform: uppercase;
  padding: 5px 0;

  @include mobileTabletPortrait {
    padding: 4px;
    font: normal normal 500 14px $afibelFont;
  }

  @include mobile {
    font: normal normal 500 11px $afibelFont;
  }
}

.topSticker {
  display: flex;
  flex-direction: column;
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  @include mobile {
    height: 18px;
    width: 100%;
  }
}