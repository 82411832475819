@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.carouselProducts {
  button.splide__arrow {
    top: calc(50% - 25px);
    width: 25px;
    height: 50px;
    opacity: 0.8;
    background: $pure-white;
    
    svg {
      display: none !important;
    }

    &::before {
      border-left: 2px solid;
      border-top: 2px solid;
      content: '';
      display: block;
      height: 14px;
      width: 14px;
      border-color: #464646;
      margin-left: -5px;
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
  }

  button.splide__arrow:disabled {
    opacity: 0.33;
  }

  button.splide__arrow--next {
    border-radius: 100px 0 0 100px;
    right: 0;
  }

  button.splide__arrow--prev {
    border-radius: 0 100px 100px 0;
    left: 0;

    &::before {
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      margin-left: 4px;
    }
  }
}

.carouselBubbleImage, .bgCarouselBubbleImage {
  display: none;
  @include desktop {
    display: block;
  }
  margin-left: 33%;

  .splide__arrow {
    background-color: $pure-white;
    color: #959595;
    border: 1px solid #959595;
  }

  .splide__arrow--prev {
    left: -32px;
  }

  .splide__arrow--next {
    right: -28px;
  }
}

.bgCarouselBubbleImage {
  margin-left: 42%;
}
