@import 'src/assets/styles/common/settings';

.userMobileMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 30px;
  background: #F4F2F2;
  box-shadow: 0px -3px 6px #00000029;
  box-sizing: border-box;
  z-index: 100;

  .navLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    text-decoration: none;
    position: relative;

    span {
      font: normal normal 500 9px/11px $font-lato;
      color: #7A7A7A;
    }

    .nbProducts {
      color: white;
      font-weight: bolder;
      position: absolute;
      text-align: center;
      top: 11px;
      right: -9px;
      width: 18px;
      height: 18px;
      background: #e5007e;
      border-radius: 100%;
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      height: auto;
      width: auto;
    }
  }
}
