@import 'src/assets/styles/common/settings';

.container {
  display: flex;
  line-height: normal;
  list-style-type: none;
  margin: 0;
  padding-left: 0;

  .menuItem {
    flex: 1 1 auto;
  }
}
