@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.container {
  &::after {
    content: '';
    background-color: red;
  }

  & > form {
    display: flex;
    gap: 5px;

    .inputContainer {
      width: 100%;
    }

    & > .close {
      display: block;
      align-self: center;
    }
  }
}

.inputContainer {
  display: flex;
  align-items: stretch;
  background-color: $grey-5;
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;

  .searchIconWrapper {
    display: flex;
    align-items: center;
    padding: 0 10px 0 15px;

    .searchIcon {
      color: $grey;
      font-size: 18px;
    }
  }

  .searchBar {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    box-sizing: border-box;
    color: $grey-2;
    font: normal normal normal 1rem $font-lato;
    background-color: inherit;
    border: none;
    width: 100%;

    &:focus,
    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: $grey-3;
      opacity: 1;
    }
  }

  .searchButton {
    display: block;
    border-left: 1px solid $grey-4;
    padding: 0 12px;
    color: #616161;
    font: normal normal 500 12px/19px $afibelFont;
  }

  .close {
    display: none;
  }
}

.bodyAutoCompletionOpen {
  overflow: hidden;
}

.autocomplete {
  z-index: 1000;
  background-color: $pure-white;
  left: 0;
  position: absolute;
  width: 100%;
  top: calc(100% - 1px);
  height: calc(100vh - 100% + 1px);
  overflow: auto;

  .categories {
    background-color: $grey-light-v2;
    grid-area: 1 / 1 / 3 / 2;
    padding: 40px;

    span {
      display: block;
      font-weight: 600;
      margin-bottom: 40px;
    }

    .categoriesResult {
      padding: 10px 0 0 0;

      .categoryLink {
        color: $pure-black;
        text-decoration: none;

        div {
          margin-bottom: 20px;
        }

        em {
          font-style: normal;
          font-weight: 500;
        }
      }

      header {
        background-color: $grey-light-v2;
        box-shadow: none;

        a {
          display: inline-block;
          background-color: $pure-white;
          border-radius: 5px;
          margin: 0 10px 0 0;
          padding: 5px 15px;
          color: $pure-black;
          text-decoration: none;
        }

        em {
          font-style: normal;
          font-weight: 500;
        }
      }
    }
  }

  .products {
    margin-bottom: 20px;
  }

  .productsHeader {
    display: flex;
    justify-content: space-between;
    padding: 20px;

    span {
      font-weight: 600;
      margin: 20px 0 0 20px;
    }

    button {
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  .productsResult {
    display: grid;
    padding: 20px;
  }

  .productLink {
    color: $pure-black;
    padding: 15px 10px 15px 0;
    text-align: center;
    text-decoration: none;

    span em {
      font-weight: bold;
    }

    img {
      margin: 0 auto;
    }

    .productContainer {
      align-items: flex-start;
      display: flex;
      justify-content: center;
      position: relative;

      .details {
        .shortLabel {
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
        }

        .searchPriceContainer {
          bottom: 0;
          padding: 5px 0;
          position: absolute;
          text-align: left;
        }

        .discount {
          display: none;
        }
      }
    }
  }

  .seeAll {
    margin: 10px 0 40px;
    text-align: center;
    display: flex;
    justify-content: center;

    button {
      background-color: $pure-black;
      border: 1px solid $pure-black;
      color: $pure-white;
      cursor: pointer;
      font: normal normal 450 15px/19px $afibelFont;
      height: 50px;
      padding: 0 25px;
      text-transform: uppercase;

      span {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }

    svg {
      margin-left: 8px;
      vertical-align: bottom;
      width: 15px;
    }
  }

  // Carousel
  button.prevArrow {
    left: 0;
    top: 30%;
  }

  button.nextArrow {
    right: 0;
    top: 30%;
  }

  .productThumbnail {
    min-width: 121px;
  }
}

@include desktop {
  .bodyAutoCompletionOpen {
    overflow: inherit;
  }

  .container {
    & > form > .close {
      display: none;
    }
  }

  .autocomplete {
    max-height: calc(100vh - 100% + 1px);
    height: auto;
    bottom: auto;
  }

  .inputContainer {
    .searchBar {
      font-size: 1.188rem;
      line-height: 1.438rem;
    }

    .close {
      align-items: center;
      background-color: $grey-5;
      display: flex;

      > button {
        all: unset;
        cursor: pointer;
        height: 24px;
        margin-right: 10px;
        width: 24px;

        > svg {
          color: $disabled-grey;
        }
      }
    }
  }

  .productsResult {
    grid-template-columns: repeat(5, 1fr);
  }

  .productLink {
    .productContainer {
      .details {
        padding: 15px 0 15px 10px;

        .shortLabel {
          -webkit-line-clamp: 4;
        }
      }
    }
  }
}

@include desktopTablet {
  .autocomplete {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;

    .productsResult {
      grid-template-columns: repeat(4, 1fr);
    }

    .productLink {
      .productContainer {
        .details {
          padding: 20px 0 20px 5px;
          text-align: left;
          vertical-align: top;
          width: 50%;

          .shortLabel {
            -webkit-line-clamp: 3;
          }
        }
      }
    }
  }
}

@include tabletLandscape {
  .inputContainer {
    height: 42px;
  }
}

@include mobileTabletPortrait {
  .inputContainer {
    height: 42px;
  }

  .autocomplete {
    overflow: auto;

    .categories {
      padding: 8px 12px 0 12px;

      span {
        margin-bottom: 15px;
      }
    }

    .productsHeader {
      padding: 8px 12px 15px 12px;

      button {
        display: none;
      }

      span {
        margin: 0;
      }
    }

    .productsResult {
      display: block;
      padding: 0;
    }

    .productLink {
      .productContainer {
        .details {
          display: block;

          .shortLabel {
            -webkit-line-clamp: 2;
          }

          .searchPriceContainer {
            text-align: center;
            width: 100%;
          }
        }
      }
    }

    .productThumbnail {
      height: auto;
      margin: 0 auto;
      max-width: 121px;
      min-width: auto;
    }
  }
}

@include tabletPortrait {
  .autocomplete {
    .categories {
      padding: 8px 70px 0 70px;
    }

    .productsHeader {
      padding: 8px 70px 40px 70px;
    }
  }
}

@include mobile {
  .autocomplete {
    .productThumbnail {
      max-width: 146px;
      min-width: auto;
    }
  }
}
