@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.link {
  text-decoration: none;
  color: $grey-2;
  font: normal normal normal 0.875rem/1.063rem $font-lato;
  letter-spacing: 0;

  &:hover {
    text-decoration: underline;
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
}