@import 'src/assets/styles/common/settings';
@import 'assets/styles/common/tools';

.container {
  background-color: #fff;
  border: $accountBorder;
  border-radius: $accountBorderRadius;
  padding: 15px;
}

@include desktopTablet() {
  .container {
    border: 0.5px solid $grey-default;
  }
}
