div.backdrop {
  background-color: rgba(51, 51, 51, 0.55);
  z-index: 2000;
  &.noPortal {
    position: absolute;
    z-index: -1;
  }
}

.disableScrollbar {
  overflow: hidden;
}
