@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.productLink {
  color: $pure-black;
  text-align: center;
  text-decoration: none;
  display: block;
  padding: 0;
  span em {
    font-weight: bold;
  }
  img {
    display: inline-block;
  }

  .productContainer {
    position: relative;
    .topSticker {
      display: flex;
      flex-direction: column;
      height: 30px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;     
      
      .sticker {
        background: rgb(218, 0, 85);
        color: $pure-white;
        cursor: pointer;
        font: normal normal 500 15px $afibelFont;
        text-align: center;
        text-transform: uppercase;
        padding: 5px 0;
  
        @include mobileTabletPortrait {
          padding: 4px;
          font: normal normal 500 14px $afibelFont;
        }
  
        @include mobile {
          font: normal normal 500 11px $afibelFont;
        }
      }
    }

    .details {
      .shortLabel {
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        padding: 8px 0;
      }
      .searchPriceContainer {
        bottom: 0;
        padding: 0;
        text-align: center;
        width: 100%;
        .newPriceContainer {
          display: block;
        }
      }
      .hideDiscount {
        display: none;
      }
    }
  }
}
