@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.container {
  background-color: $pure-white;
  line-height: normal;
  position: relative;

  .menu, .searchInputGroup {
    height: 45px;
  }

  &.sticky {
    .navigation {
      padding-left: 0;
      box-sizing: border-box;
    }

    .menu, .searchInputGroup {
      height: 55px;
    }

    .rightNavigation {
      display: block;
    }

    .searchBar {
      right: 150px;
      visibility: hidden;
      z-index: 1;
    }

    .menu .menuItem > a > span::after {
      bottom: -9px;
    }
  }

  &.searchOpen {
    .searchBar {
      width: calc(100vw - 40px);
    }
  }

  &.sticky.searchOpen {
    .searchBar {
      display: block;
      visibility: visible;
      right: 0;
    }
  }
}

.menu {
  flex-grow: 1;
  margin-right: 40px;
}

.navigation {
  display: flex;
  margin: 0 auto;
  max-width: $page-max-width;
  padding: 0 20px;
}

.rightNavigation {
  display: none;
}

.rightSide {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: right;
  max-width: 368px;
  position: relative;
  width: 100%;
}

.searchBar {
  max-width: $page-max-width;
  position: absolute;
  width: 100%;

  transition-property: right, width;
  transition-duration: 0.2s;
}
