@import 'src/assets/styles/common/settings';
@import 'src/assets/styles/common/tools';

.container {
  padding-right: 5px;

  .nbProducts {
    color: white;
    font-weight: 500;
    position: absolute;
    text-align: center;
    top: 8px;
    right: 0;
    width: 11px;
    height: 11px;
    background: #e5007e;
    border-radius: 100%;
    font-family: $font-lato;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@include minTablet {
  .container {
    padding-right: 10px;
    .nbProducts {
      width: 17px;
      height: 17px;
      top: 10px;
      font-size: 12px;
    }
  }
}
